header {
  padding: 10px 0;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .col-12 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .logo {
    display: block;
    margin: auto;
    width: 350px;
    max-width: 100%;
  }

  a:hover {
    text-decoration: none;
  }

  .motchecker,
  .telephone {
    background: $primary-color;
    padding: 10px;
    border-radius: 20px;
    width: 400px;
    max-width: 100%;
    margin: auto;

    @media (max-width: 767px) {
      margin-top: 20px;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;

    svg,
    h2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    svg {
      fill: #fff;
      height: 40px;
      width: 40px;
      margin-left: 20px;
    }

    h2 {
      color: #fff;
      margin-bottom: 0;

      @media (max-width:991px) {
        font-size: 1.25rem;
      }
    }
  }

  .telephone {
    width: auto;
    padding: 10px 15px;
  }
}